import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { vuetify } from "@/plugins/vuetify";
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

const ToastOptions = {
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true,
};

const app = createApp(App)

app
    .use(vuetify)
    .use(store)
    .use(Toast, ToastOptions)
    .use(autoAnimatePlugin)
    .use(router)

app.mount('#app');
