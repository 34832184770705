import axios from 'axios';
import store from '@/store';
import { useToast } from "vue-toastification";
const toast = useToast()

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://yourapi.com',
});

api.interceptors.request.use(
    (config) => {
        const token = store.state.auth.accessToken;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        console.log(error)
        if (error?.response?.status === 401 && !originalRequest?._retry) {
            originalRequest._retry = true;
            await store.dispatch('auth/refreshToken');
            originalRequest.headers.Authorization = `Bearer ${store.state.auth.accessToken}`;
            return api(originalRequest);
        }
        if (error?.response?.data?.errors && error?.response?.data?.errors[0]?.detail) {
            toast.error(error?.response?.data?.errors[0]?.detail);
        } else if (error?.response?.message) {
            toast.error(error.response.message);
        } else {
            toast.error(error.message);
        }
        return Promise.reject(error);
    }
);

export default api;
