import api from '@/api/api';

const state = {
    accessToken: localStorage.getItem('accessToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
};

const mutations = {
    SET_TOKENS(state, { access_token, refresh_token }) {
        state.accessToken = access_token;
        state.refreshToken = refresh_token;
        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('refreshToken', refresh_token);
        window.location.replace('/')
    },
    CLEAR_TOKENS(state) {
        state.accessToken = '';
        state.refreshToken = '';
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.replace('/')
    },
};

const actions = {
    async login({ commit }, credentials) {
        const response = await api.post('/v1/auth/token', credentials);
        commit('SET_TOKENS', response.data);
    },
    async refreshToken({ commit, state }) {
        if (!state.refreshToken) {
            commit('CLEAR_TOKENS');
        }
        const response = await api.post('/v1/auth/token/refresh', {
            refresh_token: state.refreshToken,
        });
        commit('SET_TOKENS', response.data);
    },
    logout({ commit }) {
        commit('CLEAR_TOKENS');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
