import api from '@/api/api';

const state = {
    userProfile: null,
};

const mutations = {
    SET_USER_PROFILE(state, profile) {
        state.userProfile = profile;
    },
};

const actions = {
    async fetchUserProfile({ commit }) {
        try {
            const response = await api.get('/v1/account/info');
            commit('SET_USER_PROFILE', response.data);
        } catch (error) {
            console.error('Failed to fetch user profile:', error);
        }
    },
    async updateUserProfile({ commit }, profile) {
        try {
            const response = await api.put('/v1/user/profile', profile);
            commit('SET_USER_PROFILE', response.data);
        } catch (error) {
            console.error('Failed to update user profile:', error);
        }
    },
};

const getters = {
    userProfile: state => state.userProfile,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
