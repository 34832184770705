export default function authMiddleware(to, from, next) {
    const isAuthenticated = !!localStorage.getItem("accessToken");
    
    if (to.name === 'Login' && isAuthenticated) {
        next('/');
    } else if (!isAuthenticated && to.name !== 'Login') {
        next({ name: 'Login' });
    } else {
        next();
    }
}